import _objectSpread from "/usr/local/src/local-store-h5/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { ref, computed } from 'vue';
import theme1 from '@/components/ThemeConfig/theme1';
const THEME_OBJ = {
  theme1
};
export default {
  name: 'ThemeConfig',
  props: {
    theme: {
      default: () => ({})
    }
  },
  setup(props) {
    let themeName = ref('theme1');
    const proTheme = props.theme || {};
    const theme = computed(() => _objectSpread(_objectSpread({}, THEME_OBJ[themeName.value]), proTheme));
    return {
      themeClass: themeName,
      themeVars: theme
    };
  }
};