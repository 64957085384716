import { ref, computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import {
  defStoreInfoForm,
  formatStoreManagementMenus,
  hasEntry,
  hasForHere,
  SAVE_STORE_INFO, GET_STORE_INFO,
  SAVE_MENUS_DATA, GET_MENUS_DATA,
  SAVE_USER_FUNC, GET_USER_FUNC,
} from '@/stores/permission/util';
import { getAdminMenus, getUserFuncList } from '@/api/system';
import { getStoreInfo } from '@/api/store';
import { MY_STORE_COUPON } from '@/const/dictionary/TAB_BAR_ITEMS';

const TAB_BAR = require('../../const/dictionary/TAB_BAR_ITEMS');

export const $permission$stores = defineStore(`$permission$stores`, () => {
  const storeLoading = ref(false);
  const storeInfo = reactive(defStoreInfoForm());
  const menusLoading = ref(false);
  const menusResponseData = ref([]);
  const funcLoading = ref(false);
  const userFunc = ref([]);

  const loading = computed(() => storeLoading.value || menusLoading.value || funcLoading.value);
  const isEnableStore = computed(() => +storeInfo.enable === 1);
  const isShowStoreTab = computed(() => hasEntry(menusResponseData.value));
  const isShowForHereTab = computed(() => hasForHere(userFunc.value));
  const isShowCustomerServiceTab = computed(() => menusResponseData.value.some(e => e['menuCode'] === '/pages/storeCustomer/index'));
  const homeTabsList = computed(() => {
    const storeBar = isEnableStore.value && isShowStoreTab.value ? [TAB_BAR.STORE_BAR_ITEM] : [];
    const forHereBar = isEnableStore.value && isShowForHereTab.value ? [TAB_BAR.FORHERE_LIST_BAR_ITEM] : [];
    const orderBar = isEnableStore.value ? [TAB_BAR.MY_ORDER_LIST_ITEM] : [];
    const customerServiceBar = isEnableStore.value && isShowCustomerServiceTab.value ? [TAB_BAR.CUSTOMER_SERVICE] : [];
    return [
      TAB_BAR.HOME_BAR_ITEM,
      // ...orderBar,
      // ...forHereBar,
      ...storeBar,
      TAB_BAR.MY_STORE_COUPON,
      ...customerServiceBar,
      // TAB_BAR.MY_BAR_ITEM,
    ];
  });
  const storeMenus = computed(() => formatStoreManagementMenus(menusResponseData.value));
  const funcList = computed(() => userFunc.value.map(e => e['menuCode']));

  function setStoreInfo(data) {
    data = data || {};
    for (const key in defStoreInfoForm())
      storeInfo[key] = data.hasOwnProperty(key) ? data[key] : null;
    SAVE_STORE_INFO({ ...storeInfo });
  }

  function initStoreInfo() {
    if (storeLoading.value) return;
    storeLoading.value = true;
    return getStoreInfo().then(res => {
      setStoreInfo(res);
      storeLoading.value = false;
    });
  }

  function setMenusResponseData(data) {
    menusResponseData.value = Array.isArray(data) ? data : [];
    SAVE_MENUS_DATA(menusResponseData.value);
  }

  async function initMenusData() {
    menusLoading.value = true;
    await initStoreInfo();
    return getAdminMenus({ storeId: storeInfo.id }).then(res => {
      setMenusResponseData(res);
      menusLoading.value = false;
    });
  }


  function setUserFuncList(data) {
    userFunc.value = Array.isArray(data) ? data : [];
    SAVE_USER_FUNC(userFunc.value);
  }

  function initUserFuncData() {
    funcLoading.value = true;
    return getUserFuncList().then(res => {
      setUserFuncList(res);
      funcLoading.value = false;
    });
  }

  function getHasMenuByName(name) {
    return initMenusData().then(() => {
      return name && menusResponseData.value.some(e => e.menuName === name);
    });
  }

  function getHasFuncByMenuCode(menuCode) {
    return initUserFuncData().then(() => {
      return menuCode && funcList.value.some(e => e === menuCode);
    });
  }

  async function init() {
    return Promise.all([initMenusData(), initUserFuncData()]);
  }

  setStoreInfo(GET_STORE_INFO());
  setMenusResponseData(GET_MENUS_DATA());
  setUserFuncList(GET_USER_FUNC());

  return {
    loading, storeLoading, menusLoading, funcLoading,

    storeInfo, isEnableStore,

    homeTabsList, isShowStoreTab, isShowForHereTab,

    menusResponseData,

    storeMenus,

    userFunc, funcList,

    init, initStoreInfo, initMenusData, initUserFuncData, getHasMenuByName, getHasFuncByMenuCode,
  };
});
