import request from '@/plugins/axios';

// 获取购物用户信息
export function getUserInfo(params = {}, opts = {}) {
  return request({
    url: '/shop/userInfo/getUserInfo',
    method: 'get',
    params,
    ...opts,
  });
}

// 获取用户余额
export function getUserAmount(params = {}, opts = {}) {
  return request({
    url: '/shop/userInfo/getUserAmount',
    method: 'get',
    params,
    ...opts,
  });
}

export function getIsVipUser() {
  return new Promise(resolve => {
    request({
      url: '/shop/userInfo/getUserInfo',
      method: 'get',
    }).then(res => {
      const {
        vip
      } = res || {};
      resolve(+vip > 0);
    })
  })
}

export function getIsOwnUser() {
  return new Promise(resolve => {
    request({
      url: '/shop/userInfo/getUserInfo',
      method: 'get',
    }).then(res => {
      const {
        own
      } = res || {};
      resolve(own === true);
    })
  })
}

// 获取用户分享信息
export function getUserShareInfo(params = {}, opts = {}) {
  return request({
    url: '/shop/userInfo/share',
    method: 'get',
    params,
    ...opts,
  });
}

// 分享绑定用户
export function bindUseByShareCode(params = {}, opts = {}) {
  return request({
    url: '/shop/userInfo/bind',
    method: 'get',
    params,
    ...opts,
  });
}

export function updateUserInfo(data = {}, opts = {}) {
  return request({
    url: '/shop/userInfo/updateUserInfo',
    method: 'post',
    data,
    ...opts,
  })
}

// 新增店铺会员
export function addAdminUser(params = {}, opts = {}) {
  return request({
    url: '/shop/admin/user',
    method: 'post',
    params,
    ...opts,
  })
}

// 获取会员基本信息
export function getAdminUserBaseInfo(params = {}, opts = {}) {
  return request({
    url: `/shop/admin/user/base/${params.userId}`,
    method: 'get',
    params,
    ...opts,
  })
}

// 店铺会员列表
export function getAdminUserPage(params = {}, opts = {}) {
  return request({
    url: '/shop/admin/user/page',
    method: 'get',
    params,
    ...opts,
  })
}

// 获取推荐人(股东)列表
export function getAdminShareholdersPage(params = {}, opts = {}) {
  return request({
    url: '/shop/admin/shareholders',
    method: 'get',
    params,
    ...opts,
  })
}

// 新增推荐人(股东)
export function addAdminShareholder(data = {}, opts = {}) {
  return request({
    url: '/shop/admin/shareholders',
    method: 'post',
    data,
    ...opts,
  })
}

// 获取推荐人(股东)详情
export function getAdminShareholderDetail(params = {}, opts = {}) {
  return request({
    url: `/shop/admin/shareholder/detail/${params.userId}`,
    method: 'get',
    params,
    ...opts,
  })
}

// 修改推荐人(股东)信息
export function editAdminShareholder(data = {}, opts = {}) {
  return request({
    // url: '/shop/admin/shareholders',
    url: `/shop/admin/shareholder/detail/${data.userId}`,
    method: 'put',
    data,
    ...opts,
  })
}

// 推荐人默认奖励方案列表
export function getAdminShareholdersSchemeDefalutList(params = {}, opts = {}) {
  return request({
    url: '/shop/admin/shareholder/scheme/defalut',
    method: 'get',
    params,
    ...opts,
  })
}

// 推荐人可选奖励方案列表
export function getAdminShareholdersSchemeList(params = {}, opts = {}) {
  return request({
    url: '/shop/admin/shareholder/scheme/list',
    method: 'get',
    params,
    ...opts,
  })
}

// 分享小程序码
export function getUserInfoMinicode(params = {}, opts = {}) {

  // return new Promise(resolve => {
  //   resolve({
  //     share: true,
  //     image: 'https://store-test.yzkpshop.cn/download/minicode/store/LSF-YZKP.png'
  //   })
  // })

  return request({
    url: '/shop/userInfo/minicode',
    method: 'get',
    params,
    ...opts,
  })
}

// 实名认证
export function realNameAuth(params = {}, opts = {}) {
  return request({
    url: `/shop/userInfo/realName`,
    method: 'get',
    params,
    ...opts,
  })
}

// 店铺获取会员积分信息
export function getUserPointRecords(params = {}, opts = {}) {
  return request({
    url: `/shop/admin/user/point/${params.userId}`,
    method: 'get',
    params,
    ...opts,
  })
}

// 店铺调整用户积分
export function changeUserPoint(data = {}, opts = {}) {
  return request({
    url: `/shop/admin/user/point/change`,
    method: 'put',
    data,
    ...opts,
  })
}

// 用户扫码成为会员
export function userScanningCode(params = {}, opts= {}) {
  return request({
    url: '/shop/userInfo/userScanningCode',
    method: 'get',
    params,
    ...opts,
  });
}


// 登录前获取项目信息
export function getProjectInfo(params = {}, opts= {}) {
  return request({
    url: '/shop/projectInfo/current',
    method: 'get',
    params,
    ...opts,
  });
}
