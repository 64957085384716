import { throttle } from '@/util';
export default {
  name: 'PageContainer',
  props: {
    scrollTop: {
      default: () => 0
    }
  },
  beforeMount() {
    this.handleOnScroll = throttle(this.handleOnScroll, 1000 / 30);
  },
  mounted() {},
  methods: {
    handleOnScroll() {
      var _this$$refs, _this$$refs$$pageCont;
      const top = this === null || this === void 0 ? void 0 : (_this$$refs = this.$refs) === null || _this$$refs === void 0 ? void 0 : (_this$$refs$$pageCont = _this$$refs.$pageContainer) === null || _this$$refs$$pageCont === void 0 ? void 0 : _this$$refs$$pageCont.scrollTop;
      top !== undefined && this.$emit('update:scrollTop', top);
    },
    scrollToTop(top) {
      this.$refs.$pageContainer.scroll(0, top);
    }
  }
};