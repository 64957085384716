import { HOME_CLASSIFY_TYPE_DIC } from '../dictionary/HOME_CLASSIFY_TYPE.js';

export const HOME_BAR_ITEM = {
  imgName: 'home_tab',
  text: '店铺首页',
  url: '/home/home',
};

export const GOODS_BAR_ITEM = {
  imgName: 'goods_tab',
  text: '商品',
  url: '/home/goods',
};

export const MY_ORDER_LIST_ITEM = {
  imgName: 'order_tab',
  text: '订单',
  url: '/home/order',
};

export const FORHERE_LIST_BAR_ITEM = {
  imgName: 'forhere_tab',
  text: '堂食', // 这个tabBar可能叫堂食、无糖厨房
  url: '/home/forhereList',
  type: HOME_CLASSIFY_TYPE_DIC.FORHERE,
};

export const STORE_BAR_ITEM = {
  imgName: 'shop_tab',
  text: '店铺管理',
  url: '/home/store',
};

export const MY_BAR_ITEM = {
  imgName: 'my_tab',
  text: '我的',
  url: '/home/my',
};

export const MY_STORE_COUPON = {
  imgName: 'goods_tab',
  text: '优惠券',
  url: '/home/myStoreCoupon',
}

export const CUSTOMER_SERVICE = {
  imgName: 'my_tab',
  text: '客服',
  url: '/home/customerService',
}
