import { createRouter, createWebHashHistory } from 'vue-router';
import { GET_TOKEN } from '@/util/storage';

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'boot',
      component: () => import('../views/boot.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/login.vue')
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/homeTabsPage.vue'),
      children: [
        {
          path: '/home/home',
          name: 'homeHome',
          meta: { keepAlive: true },
          component: () => import('@/views/home/home/index.vue'),
        },
        {
          path: '/home/order',
          name: 'homeOrder',
          meta: { keepAlive: true },
          component: () => import('@/views/home/order/index.vue'),
        },
        {
          path: '/home/store',
          name: 'homeStore',
          meta: { keepAlive: true },
          component: () => import('@/views/home/store/index.vue'),
        },
        {
          path: '/home/my',
          name: 'homeMy',
          component: () => import('@/views/home/my/index.vue'),
        },
        {
          path: '/home/myStoreCoupon',
          name: 'myStoreCoupon',
          component: () => import('@/views/myStoreCoupon.vue')
        },
        {
          path: '/home/customerService',
          name: 'customerService',
          meta: { keepAlive: true },
          component: () => import('@/views/home/customerService.vue')
        }
      ],
    },
    {
      path: '/home2',
      name: 'home2',
      component: () => import('@/views/home2Tab.vue'),
      children: [
        {
          path: '/home2/home2',
          name: 'homeHome2',
          meta: { keepAlive: true },
          component: () => import('@/views/home2/home2/index.vue'),
        },
        {
          path: '/home2/goodsType',
          name: 'goodsType',
          meta: { keepAlive: true },
          component: () => import('@/views/home2/goodsType/index.vue'),
        },
        {
          path: '/home2/home2Cart',
          name: 'home2Cart',
          meta: { keepAlive: true },
          component: () => import('@/views/home2/home2Cart.vue'),
        },
        {
          path: '/home2/my',
          name: 'home2My',
          component: () => import('@/views/home2/my/index.vue'),
        },
      ],
    },
    {
      path: '/goods',
      name: 'goods',
      meta: { keepAlive: true },
      component: () => import('@/views/goods/index.vue'),
    },
    {
      path: '/goods/comment',
      name: 'goodsComment',
      component: () => import('@/views/goods/comment.vue'),
    },
    {
      path: '/goodsDetail',
      name: 'goodsDetail',
      component: () => import('@/views/goodsDetail.vue'),
    },
    {
      path: '/cart',
      name: 'cart',
      component: () => import('@/views/cart.vue'),
    },
    {
      path: '/payment',
      name: 'payment',
      meta: { keepAlive: true },
      component: () => import('@/views/payment/paymentPage.vue')
    },
    {
      path: '/paymentAddressSelect',
      name: 'paymentAddressSelect',
      component: () => import('@/views/payment/paymentAddressSelect.vue')
    },
    {
      path: '/myOrderDetail',
      name: 'myOrderDetail',
      component: () => import('@/views/myOrderDetail.vue')
    },
    {
      path: '/applyForReturn',
      name: 'applyForReturn',
      component: () => import('@/views/applyForReturn.vue')
    },
    {
      path: '/myOrderReturnDetail',
      name: 'myOrderReturnDetail',
      component: () => import('@/views/myOrderReturnDetail.vue')
    },
    {
      path: '/myOrderReturnGoodsSend',
      name: 'myOrderReturnGoodsSend',
      component: () => import('@/views/myOrderReturnGoodsSend.vue'),
      children: [
        {
          path: '/myOrderReturnGoodsSend/expressList',
          name: 'returnGoodsSendExpressList',
          component: () => import('@/views/expressList.vue')
        },
      ]
    },
    {
      path: '/adminOrderList',
      name: '/pages/adminOrder/adminOrderList/index',
      meta: { keepAlive: true },
      component: () => import('@/views/admin/adminOrder/adminOrderList/index.vue')
    },
    {
      path: '/adminBanner',
      name: '/pages/adminBanner/index',
      meta: { keepAlive: true },
      component: () => import('@/views/admin/adminBanner/adminBanner.vue'),
    },
    {
      path: '/adminBannerSelectPage',
      name: 'adminBannerSelectPage',
      component: () => import('@/views/admin/adminBanner/adminBannerSelectPage.vue'),
    },
    {
      path: '/adminGoodsRecommendList',
      name: "/pages/adminGoodsRecommend/adminGoodsRecommendList/index" ,
      meta: { keepAlive: true },
      component: () => import('@/views/admin/adminGoodsRecommend/adminGoodsRecommendList/index.vue'),
    },
    {
      path: '/goodsSelectPage',
      name: 'goodsSelectPage',
      component: () => import('@/views/admin/goodsSelectPage.vue'),
    },
    //
    {
      path: '/manualDelivery',
      name: 'manualDelivery',
      component: () => import('@/views/admin/manualDelivery.vue'),
      children: [
        {
          path: '/manualDelivery/expressList',
          name: 'manualDeliveryExpressList',
          component: () => import('@/views/expressList.vue')
        },
      ]
    },
    {
      path: '/adminOrderDetail',
      name: 'adminOrderDetail',
      component: () => import('@/views/admin/adminOrder/adminOrderDetail.vue'),
    },
    {
      path: '/adminHandlingOrderReturn',
      name: 'adminHandlingOrderReturn',
      component: () => import('@/views/admin/adminOrder/adminHandlingOrderReturn.vue'),
      children: [
        {
          path: '/adminHandlingOrderReturn/orderReturnAddressSelect',
          name: 'orderReturnAddressSelect',
          component: () => import('@/views/admin/adminOrder/orderReturnAddressSelect.vue')
        },
        {
          path: '/adminHandlingOrderReturn/orderReturnAddressModify',
          name: 'orderReturnAddressModify',
          component: () => import('@/views/adminAddress/modifyAddress/index.vue')
        },
      ]
    },
    {
      path: '/adminCouponList',
      name: '/pages/adminCoupon/adminCouponList/index',
      meta: { keepAlive: true },
      component: () => import('@/views/admin/adminCoupon/adminCouponList/index.vue')
    },
    {
      path: '/adminModifyCoupon',
      name: 'adminModifyCoupon',
      component: () => import('@/views/admin/adminCoupon/adminModifyCoupon.vue'),
      children: [
        {
          path: '/adminModifyCoupon/adminGoodsSelectPage',
          name: 'adminCouponGoodsSelectPage',
          component: () => import('@/views/admin/adminGoodsSelectPage.vue')
        },
      ]
    },
    {
      path: '/adminSendCoupon',
      name: 'adminSendCoupon',
      component: () => import('@/views/admin/adminCoupon/adminSendCoupon.vue'),
    },
    {
      path: '/adminLocalGoodsList',
      name: '/pages/goodsManagement/index',
      meta: { keepAlive: true },
      component: () => import('@/views/admin/adminLocalGoods/localGoods/index.vue'),
    },
    {
      path: '/adminLocalGoodsAuditAndModify',
      name: 'adminLocalGoodsAuditAndModify',
      component: () => import('@/views/admin/adminLocalGoods/adminLocalGoodsAuditAndModify.vue'),
      children: [
        {
          path: '/adminLocalGoodsAuditAndModify/QuillEditor',
          name: 'adminLocalGoodsAuditAndModifyQuillEditor',
          component: () => import('@/components/QuillEditor.vue')
        },
      ],
    },
    {
      path: '/goodsView',
      name: 'goodsView',
      component: () => import('@/views/admin/goodsView.vue'),
    },
    {
      path: '/adminStoreDetail',
      name: 'adminStoreDetail',
      component: () => import('@/views/admin/adminStoreDetail.vue'),
    },
    {
      path: '/adminPostageRules',
      name: '/pages/adminPostageRules/adminPostageRuleList/index',
      component: () => import('@/views/admin/adminPostageRules/postageRuleList.vue'),
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/about.vue')
    },
    {
      path: '/myCoupon',
      name: 'myCoupon',
      component: () => import('@/views/myCoupon.vue')
    },
    {
      path: '/updateUserInfo',
      name: 'updateUserInfo',
      component: () => import('@/views/updateUserInfo.vue')
    },
    {
      path: '/bankCardList',
      name: 'bankCardList',
      component: () => import('@/views/bankCardList.vue')
    },
    {
      path: '/bankCardModify',
      name: 'bankCardModify',
      meta: { keepAlive: true },
      component: () => import('@/views/bankCardModify.vue'),
    },
    {
      path: '/adaPayBankList',
      name: 'adaPayBankList',
      component: () => import('@/views/adaPayBankList.vue')
    },
    {
      path: '/addressList',
      name: 'addressList',
      component: () => import('@/views/address/addressList.vue')
    },
    {
      path: '/modifyAddress',
      name: 'modifyAddress',
      component: () => import('@/views/address/modifyAddress.vue')
    },
    {
      path: '/storeLive',
      name: 'storeLive',
      component: () => import('@/views/storeLive.vue')
    },

    {
      path: '/test',
      name: 'test',
      component: () => import('@/views/test/index.vue')

    },
    {
      path: '/searchPage',
      name: 'searchPage',
      component: () => import('@/views/searchPage.vue')
    },
    {
      path: '/orderForm',
      name: 'orderForm',
      component: () => import('@/views/orderForm.vue')
    },

    {
      path: '/storeCustomer',
      name: '/pages/storeCustomer/index',
      component: () => import('@/views/storeCustomer/index.vue')
    },
    {
      path: '/basicInfo',
      name: '/pages/basicInfo/index',
      component: () => import('@/views/basicInfo/index.vue')
    },
    {
      path: '/adminAddress/adminAddressList',
      name: '/pages/adminAddress/adminAddressList/index',
      component: () => import('@/views/adminAddress/adminAddressList/index.vue')
    },
    {
      path: '/adminAddress/modifyAddress',
      name: '/pages/adminAddress/modifyAddress/index',
      component: () => import('@/views/adminAddress/modifyAddress/index.vue')
    },
    {
      path: '/storeLiveManagement/storeLiveList',
      name: '/package-admin/pages/storeLiveManagement/storeLiveList/index',
      meta: {keepAlive: true},
      component: () => import('@/views/storeLiveManagement/storeLiveList/index.vue')
    },
    {
      path: '/storeLiveManagement/storeLiveModify',
      name: '/package-admin/pages/storeLiveManagement/storeLiveModify/index',
      component: () => import('@/views/storeLiveManagement/storeLiveModify/index.vue')
    },
    {
      path: '/storeDetail',
      name: 'storeDetail',
      component: () => import('@/views/storeDetail/index.vue'),
    },
    {
      path: '/evaluate/releaseEvaluate',
      name: 'releaseEvaluate',
      component: () => import('@/views/evaluate/releaseEvaluate/index.vue'),
    },

  ]
});

router.beforeEach((to, from, next) => {
  if (!['/login', '/'].includes(to.path)) {
    if (!GET_TOKEN()) {
      next({ name: 'boot' });
    } else {
      next();
    }
  } else
    next();
});

export default router;
