import request from '@/plugins/axios';
import { clearCookie } from '@/util';

/**
 * 用户登录
 */
export function getTokenService(data = {}) {
  clearCookie();
  return request({
    url: `/auth/oauth/token`,
    method: 'post',
    headers: {
      notNeedToken: true, // 是否不需要token。默认需要。
      getAllResponse: true, // 获取接口参数，包含code、msg、data等等。默认只返回data，不包含code、msg。
      contentType: 'application/x-www-form-urlencoded',
    },
    data,
  });
}

//退出登录


export function deleteMapping(data = {}) {
  return request({
    url: `/auth/token/logout`,
    method: 'delete',
    headers: {
      notNeedToken: true, // 是否不需要token。默认需要。
      getAllResponse: true, // 获取接口参数，包含code、msg、data等等。默认只返回data，不包含code、msg。
      contentType: 'application/x-www-form-urlencoded',
    },
    data,
  });
}

/**
 * 新用户手机登录验证码发送
 */
export function getSmsBymini(params = {}, opts = {}) {
  return request({
    url: '/shop-mobile/user/getSmsBymini',
    method: 'get',
    params,
    ...opts,
  });
}


// 获取菜单
export function getAdminMenus(params = {}, opts = {}) {
  return request({
    url: '/shop/admin/menus',
    method: 'get',
    params,
    ...opts,
  });
}


// 获取用户权限
export function getUserFuncList(params = {}, opts = {}) {
  return request({
    url: '/shop/userInfo/func',
    method: 'get',
    params,
    ...opts,
  });
}

// 门店设置客服信息
export function setStoreCustomrt(data = {}, opts = {}) {
  return request({
    url: '/shop/admin/customer',
    method: 'post',
    data,
    ...opts,
  });
}

// 门店获取客服信息
export function getStoreCustomrt(params = {}, opts = {}) {
  return request({
    url: '/shop/admin/customer',
    method: 'get',
    params,
    ...opts,
  });
}

// 用户获取客服信息
export function getUserCustomrt(params = {}, opts = {}) {
  return request({
    url: '/shop/store/customer',
    method: 'get',
    params,
    ...opts,
  });
}

// 会员等级列表
export function getVipLevelPage(params = {}, opts = {}) {
  return request({
    url: '/mgmt/vipLevel/page',
    method: 'get',
    params,
    ...opts,
  });
}

export function uploadImage(file, subPath = '/store/Image') {
  const params = new FormData();
  params.append('file', file);
  return request({
    url: `/upload${ subPath }`,
    method: 'post',
    headers: { 'contentType': 'multipart/form-data', getAllResponse: true },
    data: params,
  });
}
