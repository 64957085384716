// 首页分类入口
import {
  listToMap
} from './utils';

export const NORMAL = 'normal'; // 普通类型
export const SELF = 'self'; // 自营商品
export const FORHERE = 'forhere'; // 堂食商品
export const DATACENTER = 'dataCenter'; // 堂食商品

export const HOME_CLASSIFY_TYPE_LIST = [{
  id: NORMAL,
  name: '普通类型',
}, {
  id: SELF,
  name: '自营商品',
}, {
  id: FORHERE,
  name: '堂食商品',
}, {
  id: DATACENTER,
  name: '资料中心',
}]

export const HOME_CLASSIFY_TYPE_DIC = {
  NORMAL,
  SELF,
  FORHERE,
  DATACENTER,
}

export const HOME_CLASSIFY_TYPE = listToMap(HOME_CLASSIFY_TYPE_LIST);