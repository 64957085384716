import _objectSpread from "/usr/local/src/local-store-h5/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5d0068a1"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "__checkbox-popup-toolbar"
};
const _hoisted_2 = {
  class: "__checkbox-popup-title"
};
const _hoisted_3 = {
  class: "checkbox-popup-scroller"
};
import { computed, ref } from 'vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
export default {
  __name: 'CheckboxPopup',
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    show: {
      type: Boolean,
      default: () => false
    },
    options: {
      type: [Function, Array],
      default: () => []
    },
    props: {
      type: [null, Object],
      default: () => null
    },
    title: {
      type: String,
      default: () => '请选择'
    },
    zIndex: {
      type: [Number, String],
      default: () => 2001
    },
    beforeConfirm: {
      type: [Function, null],
      default: () => null
    }
  },
  emits: ['update:show', 'loading', 'update:modelValue', 'confirm'],
  setup(__props, {
    emit: __emit
  }) {
    const defProps = () => ({
      label: 'label',
      value: 'value'
    });
    const $props = __props;
    const $emit = __emit;
    const dataList = ref([]);
    const loading = ref(false);
    const checked = ref([]);
    const checkboxRefs = ref([]);
    const toggle = index => {
      checkboxRefs.value[index].toggle();
    };
    const values = computed({
      get() {
        return $props.modelValue;
      },
      set(val) {
        let checkList = dataList.value.filter(e => val.includes(e[valueKey.value]));
        checkList = JSON.parse(JSON.stringify(checkList));
        $emit('update:modelValue', val);
        $emit('confirm', val, checkList);
      }
    });
    const modelShow = computed({
      get() {
        const {
          show
        } = $props;
        if (show) {
          initData();
        }
        return show;
      },
      set(val) {
        $emit('update:show', val);
      }
    });
    const propData = computed(() => {
      return _objectSpread(_objectSpread({}, defProps()), $props.props || {});
    });
    const labelKey = computed(() => propData.value.label);
    const valueKey = computed(() => propData.value.value);
    async function initData() {
      const {
        options
      } = $props;
      checked.value = Array.isArray($props.modelValue) ? $props.modelValue : [];
      if (typeof options === 'function') {
        $emit('loading', true);
        loading.value = true;
        const data = await options();
        dataList.value = Array.isArray(data) ? data : [];
        $emit('loading', false);
        loading.value = false;
      } else if (Array.isArray(options)) dataList.value = JSON.parse(JSON.stringify(options));else dataList.value = [];
    }
    function onConfirm() {
      checked.value = checked.value.filter(e => dataList.value.some(item => item[valueKey.value] == e));
      const val = JSON.parse(JSON.stringify(checked.value));
      const toConfirm = () => {
        values.value = val;
        modelShow.value = false;
      };
      if (typeof $props.beforeConfirm === 'function') $props.beforeConfirm(val, toConfirm);else toConfirm();
    }
    return (_ctx, _cache) => {
      const _component_van_checkbox = _resolveComponent("van-checkbox");
      const _component_van_cell = _resolveComponent("van-cell");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_checkbox_group = _resolveComponent("van-checkbox-group");
      const _component_van_empty = _resolveComponent("van-empty");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_popup, {
        show: modelShow.value,
        "onUpdate:show": _cache[3] || (_cache[3] = $event => modelShow.value = $event),
        position: "bottom",
        round: "",
        class: "__checkbox-popup__",
        "z-index": __props.zIndex
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
          class: "__checkbox-popup-cancel",
          onClick: _cache[0] || (_cache[0] = $event => modelShow.value = false)
        }, " 取消 "), _createElementVNode("div", _hoisted_2, _toDisplayString(__props.title), 1), _createElementVNode("div", {
          class: "__checkbox-popup-confirm",
          onClick: onConfirm
        }, " 确认 ")]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_van_checkbox_group, {
          modelValue: checked.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => checked.value = $event)
        }, {
          default: _withCtx(() => [_createVNode(_component_van_cell_group, null, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataList.value, (item, index) => {
              return _openBlock(), _createBlock(_component_van_cell, {
                clickable: "",
                key: item[valueKey.value],
                title: item[labelKey.value],
                onClick: $event => toggle(index)
              }, {
                "right-icon": _withCtx(() => [_createVNode(_component_van_checkbox, {
                  name: item[valueKey.value],
                  ref_for: true,
                  ref: el => checkboxRefs.value[index] = el,
                  onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
                }, null, 8, ["name"])]),
                _: 2
              }, 1032, ["title", "onClick"]);
            }), 128))]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue"]), !dataList.value.length && !loading.value ? (_openBlock(), _createBlock(_component_van_empty, {
          key: 0,
          description: "暂无分类可选"
        })) : _createCommentVNode("", true)])]),
        _: 1
      }, 8, ["show", "z-index"]), _createVNode(OverlayLoading, {
        loading: loading.value,
        "z-index": __props.zIndex
      }, null, 8, ["loading", "z-index"])], 64);
    };
  }
};