import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, unref as _unref, withCtx as _withCtx } from "vue";
import { RouterView } from 'vue-router';
import '@/css/biaoyasong.css';
import '@/css/fengyasong.css';
export default {
  __name: 'App',
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(RouterView), null, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: undefined
        })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: undefined
        })) : _createCommentVNode("", true)]),
        _: 1
      });
    };
  }
};